import React, { Component } from 'react';
import logo from 'images/logo.png';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import strings from 'data/strings';
import api from 'common/api';

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      validated: false,
      emailAddress: '',
      subscribeInProgress: false,
      subscribeSucceeded: false,
      subscribeFailed: false
    }

    this.onSubmitSubscribe = this.onSubmitSubscribe.bind(this);
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  async onSubmitSubscribe(event) {
    event.preventDefault();

    const form = event.currentTarget;
    let isValid = form.checkValidity();
    event.stopPropagation();
    this.setState({ validated: true });

    if (!isValid) {
      return;
    }
    this.setState({ subscribeInProgress: true })
    var success = await api.callSubscribe("WattsResearch", this.state.emailAddress);
    this.setState({ subscribeInProgress: false })

    if (success) {
      this.setState({ subscribeSucceeded: true })
    }
    else {
      this.setState({ subscribeFailed: true })
    }
  }

  render() {
    return (
      <main className="mt-auto px-3">
        <img alt="Logo" src={logo} style={{ width: "100%" }} />
        <hr className="mb-5 mt-3" />
        <p className="lead">Sign-up to receive notice on our launch.</p>
        <Row className="input-group">
          <Col md="12" className="d-flex justify-content-center">
          {this.state.subscribeInProgress && (
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Subscribing</span>
            </div>
          )}
          {this.state.subscribeFailed && (
            <p className='text-danger'>{strings.subscribe.subscribeFailed}</p>
          )}
          {this.state.subscribeSucceeded && (
            <p className='text-success'>{strings.subscribe.subscribeSucceeded}</p>
          )}
          {!this.state.subscribeSucceeded && !this.state.subscribeInProgress && (
            <Form noValidate validated={this.state.validated} onSubmit={this.onSubmitSubscribe}>
              <Form.Group controlId="validationCustom01">
                <Form.Control required type="email" name="emailAddress" placeholder="Your email address" onChange={(event) => this.handleUserInput(event)} />
              </Form.Group>
              <span className="input-group-btn">
                <button className="btn btn-default" type="button">
                  <span className="glyphicon glyphicon-envelope"></span>
                </button>
              </span>
            </Form>
          )}
          </Col>
        </Row>
      </main>
    );
  }
}

export default Home;
