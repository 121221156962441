//
// This module depends on the existence of environment variable for REACT_APP_SERVER_URL.
//
export default class Api {
  static async callSendMessage(profileName, name, emailAddress, message, captcha) {
    var result = false;

    await fetch(`${process.env.REACT_APP_SERVER_URL}api/v1.0/Messaging/Message`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        profileName: profileName,
        name: name,
        emailAddress: emailAddress,
        message: message,
        'g-recaptcha-response': captcha
      })
    }).then((returnedResponse) => {
      if(returnedResponse.ok) {
        result = true;
      }
      else {
        console.log("sendMessage failed, Response: " + returnedResponse);
      }
    }).catch((error) => {
      console.log("API error" + error);
    });
    return result;
  }

  static async callSubscribe(subscriptionName, emailAddress) {
    var result = false;
    await fetch(`${process.env.REACT_APP_SERVER_URL}api/v1.0/Messaging/Subscription/Subscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subscriptionName: subscriptionName,
        emailAddress: emailAddress
      })
    }).then((returnedResponse) => {
      if(returnedResponse.ok) {
        result = true;
      }
      else {
        console.log("callSubscribe failed, Response: " + returnedResponse);
      }
    }).catch((error) => {
      console.log("API error" + error);
    });
    return result;
  }

  static async callUnsubscribe(subscriptionName, emailAddress) {
    var result = false;
    await fetch(`${process.env.REACT_APP_SERVER_URL}api/v1.0/Messaging/Subscription/Unsubscribe`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        subscriptionName: subscriptionName,
        emailAddress: emailAddress
      })
    }).then((returnedResponse) => {
      if(returnedResponse.ok) {
        result = true;
      }
      else {
        console.log("callUnsubscribe failed, Response: " + returnedResponse);
      }
    }).catch((error) => {
      console.log("API error" + error);
    });
    return result;
  }
}
