import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'Router'
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';

console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`)

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
