import React, { Component } from 'react'

class CoverFrame extends Component {
  async componentDidMount() {
    document.getElementById('html').className += 'h-100';
    document.getElementById('root').className += 'd-flex h-100 w-100 text-center text-white bg-dark shadowBox';
    document.getElementById('body').className += 'd-flex h-100 w-100 text-center text-white bg-dark';
  };

  render() {
    return (
        <div className="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
          {this.props.children}
        </div>
        );
  }
}

export default CoverFrame;
