import React, { Component } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from 'pages/Home'
import CoverFrame from 'components/CoverFrame'
import Footer from 'components/Footer'

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        <CoverFrame>
          <Switch>
            <Route exact path={`/`} component={Home} />
            <Redirect to="/" />
          </Switch>
          <Footer />
        </CoverFrame>
      </BrowserRouter>
    )
  }
}

export default Router
