import React, { Component } from 'react'

import strings from 'data/strings';

class Footer extends Component {
  render() {
    return (
      <footer className="mt-auto text-white-50">
        <p>{strings.copyright}</p>
      </footer>
    );
  }
}

export default Footer;
